.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-bg);
}

.loader {
  width: 28px;
  height: 28px;
  animation: spin 1s linear infinite;
}

.loader path {
  fill: var(--color-text);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
